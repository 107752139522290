import React, {useEffect, useState} from 'react';
import {Typography, Breadcrumbs, Link, Skeleton, useMediaQuery} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {getFirestore, collection} from 'firebase/firestore';
import moment from 'moment';
import {useTheme} from '@mui/material/styles';

import firebaseApp from '../firebase';

import {populateCollectionIds} from '../data/utils';

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const generateDynamicBreadcrumbs = (collectionName, field) => {
    return ({match}) => {
        const {params} = match;
        const {id} = params;
        const [doc, setDoc] = useState(null);
        const db = getFirestore(firebaseApp);
    
        useEffect(() => {
            let isSubscribed = true;
    
            const fetch = async() => {
                const docs = await populateCollectionIds(db, collectionName, [id]);
                
                if (isSubscribed) {
                    setDoc(docs[id]);
                }
            };
    
            fetch();
    
            return () => isSubscribed = false;
        }, [db, id])
    
        if (!doc) {
            return <Skeleton variant="text" width={150} />;
        }

        return doc[field] || id;
    };
};

const DynamicApparatusChecksBreadcrumbs = ({match}) => {
    const {params} = match;
    const {apparatusId, id} = params;
    const [doc, setDoc] = useState(null);
    const db = getFirestore(firebaseApp);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            const ref = collection(db, 'apparatus', apparatusId, 'checks');
            const docs = await populateCollectionIds(db, ref, [id]);
            
            if (isSubscribed) {
                setDoc(docs[id]);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, id, apparatusId]);

    let {createdAt} = doc || {};
    if (createdAt) {
        createdAt = moment(createdAt.toDate()).format('LLLL');
    }

    if (!doc) {
        return <Skeleton variant="text" width={150} />;
    }

    return createdAt || id;
};

const routes = [
    {path: '/members/:id', breadcrumb: generateDynamicBreadcrumbs('users', 'fullName'), breadcrumbString: 'test'},
    {path: '/members/:id/skills', breadcrumb: 'Skills'},
    {path: '/members/:id/statistics', breadcrumb: 'Statistics'},
    {path: '/members/:id/transcript', breadcrumb: 'Transcript'},
    {path: '/members/:id/jibcConsent', breadcrumb: 'JIBC Consent Form'},
    {path: '/:id', breadcrumb: generateDynamicBreadcrumbs('apparatus', 'tag')},
    {path: '/:apparatusId/weekly', breadcrumb: 'Weekly Truck Check'},
    {path: '/:apparatusId/full', breadcrumb: 'Full Truck Checks'},
    {path: '/:apparatusId/edit', breadcrumb: 'Edit Apparatus'},
    {path: '/:apparatusId/:type/:id', breadcrumb: DynamicApparatusChecksBreadcrumbs},
    {path: '/:apparatusId/:type/edit', breadcrumb: 'Edit'},
    {path: '/:apparatusId/:type/start', breadcrumb: 'Start'},
    {path: '/training-matrix', breadcrumb: 'Training Matrix'},
    {path: '/calendar', breadcrumb: 'Calendar'},
    {path: '/profile', breadcrumb: 'Profile'},
    {path: '/profile/skills', breadcrumb: 'Skills'},
    {path: '/profile/statistics', breadcrumb: 'Statistics'},
    {path: '/profile/transcript', breadcrumb: 'Transcript'},
    {path: '/members', breadcrumb: 'Members'},
    {path: '/members/new', breadcrumb: 'New'},
    {path: '/ropes', breadcrumb: 'Ropes'},
    {path: '/incidents', breadcrumb: 'Incidents'},
    {path: '/incidents/:id', breadcrumb: generateDynamicBreadcrumbs('incidents', 'incidentNumber')},
    {path: '/training', breadcrumb: 'Training'},
    {path: '/training/types', breadcrumb: 'Types'},
    {path: '/vs', breadcrumb: 'Vector Solutions'},
    {path: '/vs/activities', breadcrumb: 'Activities'},
    {path: '/sogs', breadcrumb: 'Standard Operating Guidelines'},
    {path: '/respond', breadcrumb: 'Respond'},
    {path: '/respond/:id', breadcrumb: generateDynamicBreadcrumbs('responses', 'location')},
];

const HeaderBreadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs(routes);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        try {
            const {breadcrumb} = breadcrumbs[breadcrumbs.length - 1];
            const text = breadcrumb.props.children;
            
            if (typeof text === 'string') {
                document.title = `${text} | JRFD`;
            }
        } catch(e) {
            document.title = 'JRFD';
        }
    }, [breadcrumbs]);

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{m: isSmall ? 2 : 3, ...(isSmall && {ml: 0, mr: 0})}}>
            {breadcrumbs.map((item, index) => {
                const {breadcrumb, key} = item;
                const last = breadcrumbs.length - 1 === index;

                return last ? (
                    <Typography color="textPrimary" key={key}>
                        {breadcrumb}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={key} key={key}>
                        {breadcrumb}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
};

export default HeaderBreadcrumbs;
