import React, {useCallback, useContext} from 'react';
import moment from 'moment';
import formatDuration from 'format-duration';
import {useNavigate} from 'react-router-dom';

import {DataGridPro, GridActionsCellItem} from '@mui/x-data-grid-pro';

import {Edit as EditIcon} from '@mui/icons-material';

import {verifyOfficer, IncidentTypes} from '../../data/utils';

import {UserContext} from '../../contexts/User';

import Detail from './Detail';

const Grid = ({incidents, columns = [], ...rest}) => {
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);

    const defaultColumns = [
        {
            field: 'date',
            type: 'dateType',
            headerName: 'Date',
            sortable: true,
            width: 170,
            valueFormatter: params => {
                const {value} = params || {};
                return value ? moment(value).format('MMM Do YYYY, HH:mm') : '-';
            }
        },
        // {field: 'uniqueId', headerName: '#', width: 160},
        {field: 'incidentNumber', headerName: '#', width: 160},
        {
            field: 'type',
            headerName: 'Type',
            width: 300,
            valueFormatter: params => {
                const {value} = params || {};
                return IncidentTypes[value] || value;
            }
        },
        {
            field: 'incidentTypeCode',
            headerName: 'Type',
            width: 300,
            valueGetter: params => {
                const {row} = params || {};
                const {incidentTypeCode, incidentTypeDescription} = row || {};
                return [incidentTypeCode, incidentTypeDescription].filter(Boolean).join(' - ');
            }
        },
        {
            field: 'dispatchType',
            headerName: 'Dispatch Type',
            width: 300,
            valueGetter: params => {
                const {row} = params || {};
                const {dispatch} = row || {};
                const {type} = dispatch || {};
                return type;
            }
        },
        {
            field: 'members',
            headerName: 'Members',
            width: 90,
            valueGetter: params => {
                const {value} = params || {};
                return value ? value.length : 0;
            }
        },
        {
            field: 'durations.actual',
            headerName: 'Actual Duration',
            width: 150,
            valueGetter: params => {
                const {row} = params || {};
                const {durations} = row || {};
                const {total} = durations || {};
                return total;
            },
            valueFormatter: params => {
                const {value} = params || {};
                return value ? formatDuration(value) : '-';
            }
        },
        {
            field: 'durations.salary',
            headerName: 'Duration',
            width: 100,
            valueGetter: params => {
                const {row} = params || {};
                const {durations} = row || {};
                const {salary} = durations || {};
                return salary;
            },
            valueFormatter: params => {
                const {value} = params || {};
                return value ? `${value} hr` : '-';
            }
        },
        {
            field: 'street',
            headerName: 'Location',
            flex: 1
        }
    ];

    const handleEdit = useCallback((params, e) => {
        const {row} = params;
        const {metaKey} = e;

        const url = `/incidents/${row.id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate]);

    if (isOfficer) {
        defaultColumns.push({
            field: 'actions',
            headerName: '',
            type: 'actions',
            getActions: params => {
                return [
                    <GridActionsCellItem
                        label="Edit"
                        icon={<EditIcon />}
                        onClick={e => handleEdit(params, e)}
                    />
                ]
            }
        });
    }

    const getDetailPanelContent = useCallback(({row}) => <Detail row={row} />, []);

    return (
        <DataGridPro
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'date', sort: 'desc'}
                    ]
                },
                columns: {
                    columnVisibilityModel: {
                        incidentTypeCode: false,
                        dispatchType: false,
                        'durations.actual': false
                    }
                }
            }}
            autoHeight
            rows={incidents}
            columns={[
                ...defaultColumns,
                ...columns
            ]}
            pageSizeOptions={[]}
            disableColumnFilter
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={({row}) => 'auto'}
            {...rest}
        />
    );
};

export default Grid;