import React, {useEffect, useContext, useState} from 'react';
import {
    AppBar,
    Box,
    Container,
    Toolbar,
    Drawer,
    Typography,
    IconButton,
    MenuItem,
    Menu,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    Button,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
    Autocomplete,
    TextField,
    useMediaQuery
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useNavigate, useLocation, NavLink} from 'react-router-dom';
import {
    Menu as MenuIcon,
    AccountCircle as AccountCircleIcon,
    LocalShipping as LocalShippingIcon,
    People as PeopleIcon,
    Logout as LogoutIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Hiking as HikingIcon,
    FireTruck as FireTruckIcon,
    Face as FaceIcon,
    LocalLibrary as LocalLibraryIcon,
    School as SchoolIcon,
    ChangeHistory as ChangeHistoryIcon,
    FireExtinguisher as FireExtinguisherIcon
} from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {getAuth, signOut} from 'firebase/auth';
import {getFirestore, collection, query, orderBy, getDocs} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';
import {hasFeature} from '../features';

import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import UserAvatar from '../components/UserAvatar';

const DrawerWidth = 240;

const UserDialog = (props) => {
    const {onClose, open} = props;
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const {member, currentUser} = useContext(UserContext);
    const [value, setValue] = useState(member || null);
    const [inputValue, setInputValue] = useState(member ? member.email : '');
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        onClose();
    };

    const handleSelect = () => {
        onClose(value);
    };

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const ref = collection(db, 'users');
                const q = query(ref, orderBy('lastName', 'asc'));
                const raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const {role, station, ...rest} = doc.data();

                    if (doc.id !== currentUser.uid && role !== 'STATION' && station === currentUser.station) {
                        docs.push({
                            id: doc.id,
                            uid: doc.id,
                            role,
                            station,
                            ...rest
                        });
                    }
                });

                if (isSubscribed) {
                    setUsers(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, currentUser]);
  
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select Member</DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{mt: 1, width: 300}}
                    options={users}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    getOptionLabel={option => option.fullName}
                    isOptionEqualToValue={(option, value) => option.email === value.email}
                    renderInput={(params) => <TextField {...params} label="Member" />}
                    renderOption={(props, user) => {
                        const {fullName} = user;

                        return (
                            <Box component="li" {...props}>
                                <UserAvatar user={user} sx={{mr: 1}} />
                                {fullName}
                            </Box>
                        );
                    }}
                />
            </DialogContent>
            <DialogActions sx={{mr: 1, mb: 1}}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSelect}>Switch Member</Button>
            </DialogActions>
        </Dialog>
    );
  }

const AppContainer = props => {
    const {children} = props;
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const {currentUser, member, setMember} = useContext(UserContext);
    const [activeUser, setActiveUser] = useState(member || currentUser);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const auth = getAuth(firebaseApp);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isImpersonating = !!localStorage.getItem('impersonating');

    useEffect(() => {
        setActiveUser(member || currentUser);
    }, [member, currentUser]);

    if (!currentUser) {
        return children;
    }

    const pushTo = path => {
        handleMenuClose();
        
        if (pathname !== path) {
            navigate(path);
        }
    };

    const handleProfile = event => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setProfileAnchorEl(null);
    };

    // const handleDialogOpen = () => {
    //     setDialogOpen(true);
    //     handleMenuClose();
    // };

    const handleDialogClose = newMember => {
        setDialogOpen(false);

        if (newMember) {
            setMember(newMember);
        } else if (member === null) {
            setMember();
        }
    };

    // const handleSwitchToUser = () => {
    //     setMember();
    //     handleMenuClose();
    // };

    const onSignOut = async() => {
        handleMenuClose();

        await signOut(auth);

        navigate('/login');
    };

    const onCancelImpersonation = async() => {
        localStorage.removeItem('impersonating');

        await onSignOut();
    };

    const {isOfficer, role, isAdmin = false} = currentUser || {};
    const isStation = role === 'STATION';
    const isTrainingMatrix = pathname === '/training-matrix';

    const menuItems = [
        {label: 'Apparatus', to: '/', icon: <LocalShippingIcon />},
        {label: 'Training Matrix', to: '/training-matrix', icon: <PeopleIcon />}
    ];

    if (isAdmin && hasFeature('ropes')) {
        menuItems.push({label: 'Ropes', to: '/ropes', icon: <HikingIcon />});
    }

    if (!isStation && hasFeature('incidents')) {
        menuItems.push({label: 'Incidents', to: '/incidents', icon: <FireExtinguisherIcon />});
    }

    if (isOfficer) {
        menuItems.push({label: 'Members', to: '/members', icon: <PeopleIcon />});
        menuItems.push({label: 'Respond', to: '/respond', icon: <FireTruckIcon />});
    }
    
    // menuItems.push({label: 'SOGs', to: '/sogs', icon: <SummarizeIcon />});

    if (isAdmin) {
        if (hasFeature('training')) {
            menuItems.push({divider: true});

            menuItems.push({label: 'Training', to: '/training', icon: <SchoolIcon />});
            menuItems.push({label: 'Training Types', to: '/training/types', icon: <LocalLibraryIcon />});
        }

        if (hasFeature('vectorSolutionsSync')) {
            menuItems.push({divider: true});

            menuItems.push({label: 'VS Activities', to: '/vs/activities', icon: <ChangeHistoryIcon />});
        }
    }

    // {member && (
    //     <MenuItem onClick={handleSwitchToUser}>
    //         <ListItemIcon>
    //             <ChangeCircleIcon fontSize="small" />
    //         </ListItemIcon>
    //         <ListItemText>Switch to {currentUser.fullName}</ListItemText>
    //     </MenuItem>
    // )}
    // {isStation && (
    //     <MenuItem onClick={handleDialogOpen}>
    //         <ListItemIcon>
    //             <ChangeCircleIcon fontSize="small" />
    //         </ListItemIcon>
    //         <ListItemText>Switch Member</ListItemText>
    //     </MenuItem>
    // )}

    // {(member || isStation) && <Divider />}

    return (
        <Box sx={{display: 'flex', height: '100vh'}}>
            <AppBar position="fixed" sx={{zIndex: 2000}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{mr: 2}}
                        onClick={() => setDrawerOpen(!drawerOpen)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1, display: {xs: 'block', sm: 'none'}}}>JRFD</Typography>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>Joe Rich Fire Department</Typography>
                    {activeUser && (
                        <>
                            <Button
                                color="inherit"
                                startIcon={(
                                    <UserAvatar user={activeUser} sx={{width: 30, height: 30, fontSize: '16px !important'}} />
                                )}
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={handleProfile}
                                sx={{ml: 1}}
                                aria-controls={profileAnchorEl ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={profileAnchorEl ? 'true' : undefined}
                            >
                                {activeUser.fullName}{isImpersonating && ' (Impersonating)'}
                            </Button>

                            <Menu
                                id="menu-appbar"
                                anchorEl={profileAnchorEl}
                                keepMounted
                                open={Boolean(profileAnchorEl)}
                                onClose={handleMenuClose}
                                xs={{zIndex: 4000}}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 3,
                                        zIndex: 4000,
                                        width: 300,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 4001,
                                        }
                                    }
                                }}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >
                                {(!member || isOfficer) && !isStation && (
                                    <MenuItem onClick={() => pushTo('/profile')}>
                                        <ListItemIcon>
                                            <AccountCircleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Profile</ListItemText>
                                    </MenuItem>
                                )}

                                {(!member || isOfficer) && !isStation && (
                                    <Divider />
                                )}

                                {isImpersonating && (
                                    <MenuItem onClick={onCancelImpersonation}>
                                        <ListItemIcon>
                                            <FaceIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Cancel Impersonation</ListItemText>
                                    </MenuItem>
                                )}

                                <MenuItem onClick={onSignOut}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Logout</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <UserDialog
                open={dialogOpen}
                onClose={handleDialogClose}
            />
            
            <Drawer
                variant="temporary"
                sx={{
                    width: DrawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: DrawerWidth, boxSizing: 'border-box' },
                }}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                ModalProps={{
                    keepMounted: true
                }}
            >
                <Toolbar sx={{height: 64}} />
                <Box sx={{overflow: 'auto'}}>
                    <List>
                        {menuItems.map((item, index) => {
                            const {divider} = item;

                            if (divider) {
                                return <Divider key={`menu-divider-${index}`} />;
                            }

                            return (
                                <NavLink key={`menu-item-${index}`} style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}} to={item.to}>
                                    {({isActive}) => (
                                        <ListItem selected={isActive} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(item.to);
                                                    setDrawerOpen(false);
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {item.icon}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.label} />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </NavLink>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>

            {isTrainingMatrix ? (
                <Container maxWidth={false} sx={{display: 'flex', flexDirection: 'column', flex: 1, mt: '64px'}}>
                    <HeaderBreadcrumbs />

                    <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, pb: 1}}>
                        {children}
                    </Box>
                </Container>
            ) : (
                <Container maxWidth="xl" sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, mt: '64px', p: isSmall && 1}}>
                    <HeaderBreadcrumbs />

                    <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, pl: !isSmall && 3, pr: !isSmall && 3, pb: 3}}>
                        {children}
                    </Box>
                </Container>
            )}
        </Box>
    );
};

export default AppContainer;
