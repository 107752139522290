import React, {useState, createRef, useEffect, useCallback, useContext} from 'react';

import {Box, Typography, Button, Divider} from '@mui/material';
import { Worker } from '@react-pdf-viewer/core';
import {Viewer} from '@react-pdf-viewer/core';
import { SearchIcon } from '@react-pdf-viewer/search';
import { MoreActionsPopover, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import SideBar from './sogs/SideBar';

const renderToolbar = Toolbar => {
    return (
        <Toolbar>
            {(toolbarSlot: ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Open,
                    Print,
                    SwitchTheme,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = toolbarSlot;

                return (
                    <div className="rpv-toolbar" role="toolbar" aria-orientation="horizontal">
                        
                    </div>
                );
            }}
        </Toolbar>
    );
};

const Container = () => {
    // const [loading, setLoading] = useState(true);
    // const [docs, setDocs] = useState([]);
    // const db = getFirestore(firebaseApp);
    // const {enqueueSnackbar} = useSnackbar();
    // const {currentUser} = useContext(UserContext);
    // const {isOfficer} = currentUser;

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) =>
            [
                {
                    content: (
                        <SideBar
                            searchPluginInstance={
                                defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance
                            }
                        />
                    ),
                    icon: <SearchIcon />,
                    title: 'Search',
                },
            ].concat(defaultTabs),
    });

    return (
        <Box sx={{display: 'flex', height: '80vh'}}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl="/RDCO -OG (Overview).pdf" plugins={[defaultLayoutPluginInstance]} />
            </Worker>
        </Box>
    );

};

export default Container;